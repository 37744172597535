<template>
  <base-page>
    <router-view />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SousCiblePage',

  meta () {
    return {
      titleTemplate: title => `${title} - Cibles | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    const getFields = () => !store.getters['sousCibles/getFormFields'] ? store.dispatch('sousCibles/fetchFormFields') : Promise.resolve()
    const getSousCible = () => currentRoute.params.id ? store.dispatch('sousCibles/getSousCible', currentRoute.params.id) : Promise.resolve()
    return getFields().then(getSousCible)
  },

  beforeDestroy () {
    this.$store.commit('sousCibles/setCurrentSousCible', null)
  },

  computed: {
    ...mapGetters({
      sousCible: 'sousCibles/getCurrentSousCible'
    })
  },

  watch: {
    sousCible: {
      handler (newVal, oldVal) {
        if (!newVal) return

        const { label, id } = newVal.groupe_cibles
        this.$store.commit('pages/setParentRoute', { label, name: 'cible-show', params: { id } })
        const title = this.$t(`sousCibles.fields.type.options.${newVal['type']}`)
        this.$store.commit('pages/setPageTitle', title)
        this.$store.dispatch('sousCibles/fetchFormFields', { droits_title: newVal.statut })
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('cibles.cibles_list'), to: { name: 'cibles-list' } },
      {}
    ])

    if (!this.$route.params.id) {
      this.$store.commit('pages/setPageTitle', this.$t(`sousCibles.add_sousCible`))
    }

    this.$store.commit('pages/setTabs', [])
  }
}
</script>
